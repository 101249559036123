import React, {FC, useRef} from "react";
import "./group-images.scss";
import { useIsVisible } from "../../hooks/use-is-visible";
import { Img } from "../../types/img";

type GroupImagesProps = {
  images: Img[];
};

export const GroupImages: FC<GroupImagesProps> = ({ images }) => {
  if (!images?.length) return null;

  const ref = useRef<HTMLDivElement>();
  const isVisible = useIsVisible(ref);

  return (
    <div className={isVisible ? "" : " not-visible"} ref={ref}>
      <div className="container re-order">
        <div className="row">
          {images.map((img, index) => {
            if (index === 0)
              return (
                <div className="second col-xs-12 col-md-4">
                  <img
                    className="group-images-item"
                    key={`image-${index}`}
                    src={img.url}
                    alt={img.alt}
                  />
                </div>
              );
              else if (index === 1)
              return (
                <div className="first col-xs-12 col-md-8">
                  <img
                    className="group-images-item"
                    key={`image-${index}`}
                    src={img.url}
                    alt={img.alt}
                  />
                </div>
              );
              else if (index === 2)
              return (
                <div className="third col-xs-12 col-md-8">
                  <img
                    className="group-images-item"
                    key={`image-${index}`}
                    src={img.url}
                    alt={img.alt}
                  />
                </div>
              );
              else
              return (
                <div className="last col-xs-12 col-md-4">
                  <img
                    className="group-images-item"
                    key={`image-${index}`}
                    src={img.url}
                    alt={img.alt}
                  />
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};
