import React from 'react';
import './link-with-arrow.scss';
import { Icon, Icons } from '../../controllers/icon';
import { LinkHandler } from '../../controllers/link-handler';

type LinkWithArrowProps = {
    path?: string;
    url?: string;
    title: string;
    subTitle: string;
};

export const LinkWithArrow: React.FC<LinkWithArrowProps> = ({ path, url, title, subTitle }) => {
    return (
        <LinkHandler className="link-with-arrow" path={path} url={url}>
            <div className="link-with-arrow-text">
                <h3>{title}</h3>
                <h4>{subTitle}</h4>
            </div>
            <Icon icon={Icons.ChevronRight} />
        </LinkHandler>
    );
};