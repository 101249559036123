import React from 'react';
import ReactDOM from 'react-dom';

export const Portal: React.FC<{ children: React.ReactNode, portalId: string }> = ({ children, portalId }) => {
    let portalRoot = document.getElementById(portalId);

    if (!portalRoot) {
        portalRoot = document.createElement("div");
        portalRoot.setAttribute("id", portalId);
        document.body.appendChild(portalRoot);
    }

    return ReactDOM.createPortal(children, portalRoot);
};