import React from 'react';
import { useIsPlaying } from '../../hooks/use-is-playing';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Spacing } from '../../types/spacing';
import { BgImg } from '../bg-img/bg-img';
import { PlayBtn } from '../play-btn/play-btn';
import './video.scss';

type VideoProps = {
    src: string;
    thumbnail: string;
    spacing?: Spacing;
}

export const Video: React.FC<VideoProps> = ({ src, thumbnail, spacing }) => {
    const containerRef = React.useRef<HTMLDivElement>();
    const videoRef = React.useRef<HTMLVideoElement>();
    const isVisible = useIsVisible(containerRef); // animate in when it actually enters the viewport
    const { isActive } = useIsPlaying(videoRef);
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>();

    const handleMouseEnter = () => {
        setIsMouseOver(true)
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    React.useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("mouseenter", handleMouseEnter);
            containerRef.current.addEventListener("mouseleave", handleMouseLeave);

            return () => {
                containerRef.current?.removeEventListener("mouseenter", handleMouseEnter);
                containerRef.current?.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
    }, [containerRef]);

    return (
        <div className={"container" + (spacing ? ` spacing-${spacing}` : '')}>
            <div ref={containerRef}
                className={`video` + (isVisible ? '' : ' not-visible') + (isActive ? ' active' : '')}>
                <button className="video-thumbnail" onClick={() => videoRef.current.play()} aria-label="Play video">
                    <BgImg img={{ url: thumbnail }} />
                </button>
                <video controls ref={videoRef} playsInline poster={thumbnail} >
                    <source src={src} type="video/mp4"></source>
                </video>
                <PlayBtn followMouse={isMouseOver} hidden={isActive} onClick={() => videoRef.current.play()} />
            </div>
        </div>
    );
};