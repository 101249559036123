import React from 'react';
import './featured-articles.scss';
import { LinkWithArrow } from '../link-with-arrow/link-with-arrow';
import { useIsVisible } from '../../hooks/use-is-visible';

type FeaturedArticlesProps = {
    items: {
        category: string;
        title: string;
        id?: string;
        url?: string;
    }[];
};

export const FeaturedArticles: React.FC<FeaturedArticlesProps> = ({ items }) => {
    if (!items?.length) return null;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"featured-articles spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="container">
                <div className="row layout-align-center">
                    <div className="col-xs-12 col-md-8 col-lg-6">
                        <ul>
                            {items.map((item, index) => {
                                return (
                                    <li key={`article-link-${index}`}>
                                        <LinkWithArrow 
                                        path={item.id ? `/news-and-opinion/${item.id}` : ''}
                                        url={item.url}
                                        title={item.title} 
                                        subTitle={item.category} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};