import React from 'react';
import { CaseStudyGrid } from '../components/case-study-grid/case-study-grid';
import { ImageGrid } from '../components/image-grid/image-grid';
import { PageContent } from '../components/page-content/page-content';
import { PageHeading } from '../components/page-heading/page-heading';
import { Video } from '../components/video/video';
import { useData } from '../hooks/use-data';
import { Spacing } from '../types/spacing';

const HotAndCold: React.FC = () => {
    const { loading, data, error } = useData('hot-and-cold');
    if (loading || error || !data) return null;

    return (
        <div>
            <PageHeading {...data.heading} showScroll />
            <PageContent {...data.intro} />
            <Video {...data.video} spacing={Spacing.BottomHalf} />
            <CaseStudyGrid  {...data.work} />
            <PageContent {...data.agency} />
            <ImageGrid {...data.newClients} spacing={Spacing.BottomFull} />
            <ImageGrid {...data.topClients} spacing={Spacing.BottomFull} />
        </div>
    );
};

export { HotAndCold as default }