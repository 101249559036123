import React from 'react';
import './link-underlined.scss';
import { Link } from 'react-router-dom';

type LinkUnderlinedProps = {
    url?: string;
    external?: boolean;
    target?: '_blank';
    onClick?: () => void;
    asButton?: boolean;
    children: React.ReactNode;
};

export const LinkUnderlined: React.FC<LinkUnderlinedProps> = ({ children, url, external, target, onClick, asButton }) => {
    if (asButton) {
        return <button className="link-underlined" onClick={onClick}>{children}</button>
    }

    if (!url) {
        return <p className="link-underlined">{children}</p>
    }
    
    return external ?
        <a className="link-underlined" href={url} target={target} onClick={onClick}>{children}</a> :
        <Link className="link-underlined" to={url} onClick={onClick}>{children}</Link>
};