import React from 'react';
import { AudioList } from '../components/audio-list/audio-list';
import { Banner } from '../components/banner/banner';
import { CaseStudyIntro } from '../components/case-study-intro/case-study-intro';
import { FeaturedArticles } from '../components/featured-articles/featured-articles';
import { FullWidthGif } from '../components/full-width-gif/full-width-gif';
import { GroupImages } from '../components/group-images/group-images';
import { NextPage } from '../components/next-page/next-page';
import { Spacing } from '../types/spacing';
import { StaggeredImages } from '../components/staggered-images/staggered-images';
import { Theme } from '../types/theme';
import { useData } from '../hooks/use-data';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Video } from '../components/video/video';

const CaseStudy: React.FC = () => {
    let { caseStudyId } = useParams();
    const hacMatch = useRouteMatch('/hot+cold'); // other wise its /work as before
    const dataPath = hacMatch ? `hot-and-cold/${caseStudyId}` : `case-studies/${caseStudyId}`;
    console.log('dataPath', dataPath);
    const { loading, data, error } = useData(dataPath);
    if (loading || error || !data) return null;

    return (
        <div>
            <Banner preHeading={data.clientName} heading={data.heading} img={data.img} theme={Theme.Black} />
            <CaseStudyIntro project={data.projectName} heading={data.subHeading} link={data.link} overview={data.overview} results={data.results}  />
            {!!data.video && <Video {...data.video} spacing={Spacing.BottomFull} />}
            <FeaturedArticles items={data.relatedArticles} />
            <GroupImages {...data.groupImages} />
            <StaggeredImages {...data.staggeredImages} />
            <FullWidthGif {...data.gif} />
            <AudioList items={data.audioItems} />
            <NextPage {...data.nextPage} />
        </div>
    );
};

export { CaseStudy as default }