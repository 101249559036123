import React from 'react';
import './menu.scss';
import { NavLink } from 'react-router-dom';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import { Portal } from '../../controllers/portal';
import { Logo } from '../logo/logo';

type MenuProps = {
    navLinks: {
        path: string;
        label: string;
    }[];
    socialLinks: {
        url: string;
        label: string;
    }[];
    footerLinks: {
        path: string;
        label: string;
    }[];
};

const MenuButton: React.FC<{ isOpen: boolean, isTransitioning: boolean, onClick: () => void }> = ({ isOpen, isTransitioning, onClick }) => {
    return (
        <button className={`menu-btn` + (isOpen ? ' open' : '') + (isTransitioning ? ' transitioning' : '')} onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
}

export const Menu: React.FC<MenuProps> = ({ navLinks, socialLinks, footerLinks }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [isTransitioning, setIsTransitioning] = React.useState<boolean>();
    const menuRef = React.useRef<HTMLDivElement>();

    const menuBtnClick = () => {
        setIsTransitioning(true);

        setTimeout(function () {
            let open = !isOpen;
            open ? document.body.classList.add('no-overflow') : document.body.classList.remove('no-overflow');
            if (open) menuRef.current.scrollTop = 0; // reset scroll position
            setIsOpen(open);
            setIsTransitioning(false);
        }, 500);
    };

    const linkClick = () => {
        menuBtnClick();
    };

    return (
        <React.Fragment>
            <MenuButton isOpen={isOpen} isTransitioning={isTransitioning} onClick={menuBtnClick} />

            <Portal portalId="menu-portal">
                <div className={"menu" + (isOpen ? ' open' : '')} ref={menuRef}>
                    <Logo onClick={linkClick} />
                    <MenuButton isOpen={isOpen} isTransitioning={isTransitioning} onClick={menuBtnClick} />

                    <div className="grid">
                        <nav className="nav-links">
                            <ul>
                                {navLinks.map((link, index) => {
                                    return (
                                        <li key={`nav-link-${index}`}>
                                            <NavLink exact to={link.path} onClick={linkClick}>
                                                <h2>{link.label}</h2>
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                        <div className="social-links">
                            {socialLinks.map((item, index) => {
                                return (
                                    <LinkUnderlined key={`social-link-${index}`} url={item.url} external onClick={linkClick} target="_blank">{item.label}</LinkUnderlined>
                                );
                            })}
                        </div>
                        <div className="footer-links">
                            {footerLinks.map((item, index) => {
                                return (
                                    <LinkUnderlined key={`footer-link-${index}`} url={item.path} onClick={linkClick}>{item.label}</LinkUnderlined>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Portal>
        </React.Fragment>
    );
};