import React from 'react';
import { API_BASE_URL, RECAPTCHA_SITE_KEY } from '../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import './subscribe-form.scss';

const enum SubmitState {
    NotSubmitted = "not-submitted",
    Submitting = "submitting",
    Submitted = "submitted",
    Error = "error"
};

export const SubscribeForm: React.FC = () => {

    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [submitState, setSubmitState] = React.useState<SubmitState>(SubmitState.NotSubmitted);

    const recaptchaRef = React.createRef<HTMLDivElement>();

    const isError = submitState === SubmitState.Error;
    const disabled = submitState !== SubmitState.NotSubmitted;
    const isSubmitted = submitState === SubmitState.Submitted;


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmitState(SubmitState.Submitting);
        if (firstName && lastName && email) {
            const token = await recaptchaRef.current.executeAsync();
            const submissionStatus = await submitForm(token);

            if (submissionStatus === 200) {
                setSubmitState(SubmitState.Submitted);
            }
            else {
                setSubmitState(SubmitState.Error);
            }
        }
    }

    const submitForm = async (token) => {
        const reqBody = {
            firstName,
            lastName,
            email,
            token
        };
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(reqBody)
        }).catch(err => { console.error(`Cannot register - ${err}`); return 500; })
        return response.status;
    }

    const renderSuccess = () => {
        return (<div className='success-message'><h3>Thank you for your subscription. An email with a download link will arrive shortly in your inbox.</h3></div>)
    }
    const renderError = () => {
        return (<div className='error-message'><p>Something went wrong. Please try again later.</p></div>)
    }
    const renderForm = () => {
        return (
            <div >
                {isError && renderError()}
                <form onSubmit={handleSubmit} aria-label="Subscribe" className={(!disabled ? '' : 'disabled')}>

                    <div className="input-field">
                        <label htmlFor="first-name" className="sr-only">Name</label>
                        <input
                            type="text"
                            id="first-name"
                            name="first-name"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            required
                            placeholder="First Name" />
                    </div>
                    <div className="input-field">
                        <label htmlFor="last-name" className="sr-only">Last Name</label>
                        <input
                            type="text"
                            id="last-name"
                            name="last-name"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            required
                            placeholder="Last Name" />

                    </div>
                    <div className="input-field">
                        <label htmlFor="email" className="sr-only">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                            placeholder="Email Address" />
                    </div>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={RECAPTCHA_SITE_KEY || ''}
                    />

                    <div className="form-submit">
                        <button className="submit-btn" type="submit" >Download</button>
                    </div>
                </form>
            </div>)
    }

    return (
        <div className='subscribe-form  container '>
            <div className="row">
                <div className="col-xs-12 col-md-7 col-lg-6 col-md-pre-2">
                    { isSubmitted ? renderSuccess() : renderForm()}
                </div>
            </div>
        </div>

    );
};