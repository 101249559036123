import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import './quote.scss';

type QuoteProps = {
    content: string;
    source: string;
};

export const Quote: React.FC<QuoteProps> = ({ content, source }) => {
    return (
        <figure className="quote">
            <blockquote>
                <Icon icon={Icons.Quote} />
                <p>{content}</p>
            </blockquote>
            <figcaption>
                {source}
            </figcaption>
        </figure>
    );
};