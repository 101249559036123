export const APPLY_NOW_URL = "https://career10.successfactors.com/career?company=digitaltra";
export const APPLY_NOW_PO_PSO_URL = "https://career10.successfactors.com/career?career%5fns=job%5flisting&company=digitaltra&navBarLevel=JOB%5fSEARCH&rcm%5fsite%5flocale=en%5fGB&career_job_req_id=5940";
export const APPLY_NOW_IMG = { url: "../assets/images/apply-now.jpg", alt: "Apply now"};
export const RECAPTCHA_SITE_KEY="6LcpELgfAAAAAOzchuKPaOX1eWvo5Dvrto0K9wIq";
export const API_BASE_URL="https://awwi9lho33.execute-api.ap-southeast-2.amazonaws.com/prod" 
export const BREAKPOINTS = {
    'xs': '0',
    'sm': '420px',
    'md': '1024px',
    'lg': '1280px',
    'xl': '1600px',
    'xxl': '1920px',
};