import React from 'react';
import { ArticleLinkList } from '../components/article-link-list/article-link-list';
import { PageHeading } from '../components/page-heading/page-heading';
import { useData } from '../hooks/use-data';

export const Articles: React.FC = () => {
    const { loading, data, error } = useData('articles');
    if (loading || error || !data) return null;
    
    return (
        <div>
            <PageHeading preHeading={data.preHeading} heading={data.heading} />
            <ArticleLinkList items={data.items} />
        </div>
    );
};

export { Articles as default }