import React from 'react';

export enum ScrollDirection {
  Up = "up",
  Down = "down"
}

export const useScroll = ({
  initialDirection,
  thresholdPixels
}: { initialDirection?: ScrollDirection, thresholdPixels?: number } = {}) => {
  const [scrollDir, setScrollDir] = React.useState(initialDirection);
  const [scrollPosition, setScrollPosition] = React.useState<number>();

  React.useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(scrollY > lastScrollY ? ScrollDirection.Down : ScrollDirection.Up);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      setScrollPosition(lastScrollY);
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialDirection, thresholdPixels]);

  return { scrollDir, scrollPosition };
};