import { BREAKPOINTS } from "../constants";

class UtilsUI {

  youtubeReady: any;

  initYoutubeApi = () => {
    if (!this.youtubeReady) {

      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";

      this.youtubeReady = new Promise((resolve) => {
        window.onYouTubeIframeAPIReady = () => {
          resolve(window.YT);
        }
      });

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    return this.youtubeReady;
  };

  isScreen = (size: 'xs' | 'md' | 'lg' | 'xl' | 'xxl') => {
    return window.matchMedia(`(min-width: ${BREAKPOINTS[size]})`).matches;
  };
}

let utilsUI = new UtilsUI();
export default utilsUI;