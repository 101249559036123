import React from 'react';
import { Link } from 'react-router-dom';

type LinkHandlerProps = {
    path?: string;
    url?: string;
    children: React.ReactNode,
    className: string;
};

export const LinkHandler: React.FC<LinkHandlerProps> = ({ path, url, children, className }) => {
    if (path) return (
        <Link className={className} to={path}>
            {children}
        </Link>
    );

    if (url) return (
        <a className={className} href={url} target="_blank">
            {children}
        </a>
    );

    return null;
};