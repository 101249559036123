import React from 'react';
import './full-width-gif.scss';
import { useIsVisible } from '../../hooks/use-is-visible';

type FullWidthGifProps = {
    url: string;
    alt: string
};

export const FullWidthGif: React.FC<FullWidthGifProps> = ({ url, alt }) => {
    if (!url) return null;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"full-width-gif spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="container">
                <img src={url} alt={alt} />
            </div>
        </div>
    );
};