import React from 'react';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { Theme } from '../../types/theme';
import { ScrollDown } from '../scroll-down/scroll-down';
import './page-heading.scss';

type PageHeadingProps = {
    preHeading?: string;
    heading: string;
    showScroll?: boolean;
}

export const PageHeading: React.FC<PageHeadingProps> = ({ preHeading, heading, showScroll }) => {
    const isLoaded = useIsLoaded(100);
    const element = React.useRef<HTMLDivElement>();

    const onScrollClick = () => {
        window.scrollTo({ top: element.current.clientHeight, behavior: 'smooth' });
    };

    return (
        <div className={"page-heading container" + (isLoaded ? "" : " loading")} ref={element}>
            <div className="row">
                <div className="col-xs-12 col-md-8 col-md-pre-2 heading">
                    {!!preHeading && <h4 className="pre-heading">{preHeading}</h4>}
                    <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>
            </div>
            {!!showScroll && <ScrollDown onClick={onScrollClick} theme={Theme.Black} />}
        </div>
    );
};