import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Category } from '../../types/category';
import { Img } from '../../types/img';
import { PageLinkCard } from '../page-link-card/page-link-card';
import './page-link-grid.scss';

type PageLinkGridProps = {
    items: {
        img: Img;
        category: Category;
        title: string;
        path?: string;
        url?: string;
    }[];
};

export const PageLinkGrid: React.FC<PageLinkGridProps> = ({ items }) => {
    const refs = items.map(() => React.useRef<HTMLDivElement>());
    const visibility = refs.map(ref => useIsVisible(ref));

    return (
        <div className="page-link-grid">
            <div className="row">
                {items.map((item, index) => {
                    return (
                        <div key={`page-link-${index}`} ref={refs[index]} className={`page-link-grid-item col-xs-12 ${item.category === Category.Work ? 'landscape col-md-6' : 'portait col-md-3'}` + (visibility[index] ? '' : ' not-visible')}>
                            <PageLinkCard {...item} orientation={item.category === Category.Work ? 'landscape' : 'portrait'} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
