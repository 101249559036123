import React from 'react';
import {useHistory} from 'react-router-dom';

export const useData = (key: string) => {
    let history = useHistory();
    const [result, setResult] = React.useState({ data: null, loading: true, error: null });

    const loadFromSessionStorage = (storageKey: string) => {
        try {
            let serializedState = sessionStorage.getItem(storageKey);
            return serializedState ? JSON.parse(serializedState) : null;
        }
        catch (err) {
            return null;
        }
    };

    const saveToSessionStorage = (storageKey: string, data: any) => {
        try {
            let serializedState = JSON.stringify(data);
            sessionStorage.setItem(storageKey, serializedState);
        }
        catch (err) {
        }
    };

    React.useEffect(() => {
        let data = loadFromSessionStorage(key);
        setResult({ data, loading: false, error: null });
        if (!data) {
            setResult({ data: null, loading: true, error: null });

            fetch(`/data/${key}.json`)
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        throw (res.error);
                    }
                    saveToSessionStorage(key, res);
                    setResult({ data: res, loading: false, error: null });
                })
                .catch(error => {
                    setResult({ data: null, loading: false, error });
                    history.replace("/404");
                });
        }
    }, [key]);

    return result;
};