import React from 'react';
import { AboutUsSection } from '../components/about-us-section/about-us-section';
import { Capabilities } from '../components/capabilities/capabilities';
import { NextPage } from '../components/next-page/next-page';
import { PageHeading } from '../components/page-heading/page-heading';
import { Video } from '../components/video/video';
import { useData } from '../hooks/use-data';
import { Spacing } from '../types/spacing';

const AboutUs: React.FC = () => {
    const { loading, data, error } = useData('about-us');
    if (loading || error || !data) return null;

    return (
        <div>
            <PageHeading {...data.heading} showScroll />
            <AboutUsSection {...data.intro} />
            <Video {...data.video} spacing={Spacing.BottomHalf} />
            <Capabilities {...data.capabilities} />
            <NextPage {...data.nextPage} />
        </div>
    );
};

export { AboutUs as default }