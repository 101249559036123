import React from 'react';
import './audio-list.scss';
import { useIsVisible } from '../../hooks/use-is-visible';
import { useIsPlaying } from '../../hooks/use-is-playing';

type AudioListProps = {
    items: {
        url: string;
        title: string;
    }[];
};

export const AudioList: React.FC<AudioListProps> = ({ items }) => {
    if (!items?.length) return null;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"audio-list spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="container">
                <div className="row layout-align-center">
                    <div className="col-xs-12 col-md-8">
                        <ul>
                            {items.map((item, index) => {
                                return (
                                    <li key={`audio-item-${index}`}>

                                        <Audio {...item} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Audio: React.FC<{ url: string, title: string }> = ({ url, title }) => {

    return (
        <React.Fragment>
            <p>{title}</p>
            <audio controls>
                <source src={url} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </React.Fragment>
    );
};

// const Audio: React.FC<{ url: string, title: string }> = ({ url, title }) => {
//     const ref = React.useRef<HTMLAudioElement>();
//     const { duration } = useIsPlaying(ref);

//     return (
//         <React.Fragment>
//             <p>{title}</p>
//             <p>{duration}</p>
//             <audio ref={ref}>
//                 <source src={url} type="audio/mpeg" />
//                 Your browser does not support the audio element.
//             </audio>
//         </React.Fragment>
//     );
// };