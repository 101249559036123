import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { useMatchMedia } from '../../hooks/use-match-media';
import './play-btn.scss';

export const PlayBtn: React.FC<{ hidden: boolean, followMouse: boolean, onClick: () => void }> = ({ hidden, followMouse, onClick }) => {
    const ref = React.useRef<HTMLDivElement>();
    const hasMouse = useMatchMedia('(pointer:fine)');

    const handleMouseMove = (e) => {
        ref.current.style.transform = `translate3d(${e.pageX}px, ${e.pageY}px, 0px)`;
    };

    // React.useEffect(() => {
    //     if (followMouse && hasMouse) {
    //         window.addEventListener("mousemove", handleMouseMove);
    //     }
    //     else {
    //         ref.current.style.transform = '';
    //     }

    //     return () => {
    //         window.removeEventListener("mousemove", handleMouseMove);
    //     };
    // }, [followMouse, hasMouse]);

    return (
        <div ref={ref} className={"play-btn" + (hidden ? ' hidden' : '') + (followMouse ? ' active' : '')} onClick={onClick}>
            <Icon icon={Icons.PlayButton} />
        </div>
    );
};