import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { Portal } from '../../controllers/portal';
import { useIsPlaying } from '../../hooks/use-is-playing';
import './full-screen-video.scss';

type FullScreenVideoProps = {
    src: string;
    thumbnail: string;
    play: boolean;
    onClose: () => void;
}

export const FullScreenVideo: React.FC<FullScreenVideoProps> = ({ src, thumbnail, play, onClose }) => {
    const ref = React.useRef<HTMLVideoElement>();
    const {isPlaying} = useIsPlaying(ref);

    const close = () => {
        ref.current.pause();
        ref.current.currentTime = 0; // TODO: check if they want this
        document.body.classList.remove('no-overflow');
        onClose();
    };

    const togglePause = () => {
        ref.current.paused ? ref.current.play() : ref.current.pause();
    };

    React.useEffect(() => {
        if (play) {
            document.body.classList.add('no-overflow');
            ref.current.play();
        }
    }, [play]);

    return (
        <Portal portalId="full-screen-video-portal">
            <div className={"full-screen-video" + (play ? ' playing' : '')}>
                <video controls poster={thumbnail} ref={ref}>
                    <source src={src} type="video/mp4"></source>
                </video>
                <button className="close-btn" onClick={close}>
                    <Icon icon={Icons.Close} />
                </button>
                <button className="pause-btn" onClick={togglePause}>
                    <Icon icon={isPlaying ? Icons.Pause : Icons.Play} />
                </button>
            </div>
        </Portal>
    );
};