import React from 'react';
import { PageContent } from '../components/page-content/page-content';
import { PageHeading } from '../components/page-heading/page-heading';

const data = {
    heading: {
        preHeading: '404',
        heading: `Page not found`,
    },
    pageContent: {
        heading: `Sorry, but we couldn't find that.`,
        content: []
    }
};

const PageNotFound: React.FC = () => {
    return (
        <div>
            <PageHeading {...data.heading} />
            <PageContent {...data.pageContent} />
        </div>
    );
};

export { PageNotFound as default }