import React from 'react';

export const useIsVisible = (ref, options?, setOnce = true) => {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        const observer = new window.IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
            if (entry.isIntersecting && setOnce) {
                observer.unobserve(entry.target);
            }
        }, options);

        if (ref?.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isVisible;
};