import React from 'react';
import { ContactDetails } from '../components/contact-details/contact-details';
import { PageHeading } from '../components/page-heading/page-heading';
import { Map } from '../components/map/map';
import { NextPage } from '../components/next-page/next-page';
import { useData } from '../hooks/use-data';

const Contact: React.FC = () => {
    const { loading, data, error } = useData('contact');
    if (loading || error || !data) return null;
    
    return (
        <div>
            <PageHeading preHeading={data.preHeading} heading={data.heading} />
            <ContactDetails address={data.address} directions={data.directions} tel={data.tel} email={data.email} socialLinks={data.socialLinks} />
            <Map longitude={data.longitude} latitude={data.latitude} />
            <NextPage {...data.nextPage} />
        </div>
    );
};

export { Contact as default }
