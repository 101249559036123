import React from 'react';
import { Banner } from '../components/banner/banner';
import { useParams } from 'react-router-dom';
import { Theme } from '../types/theme';
import { NextPage } from '../components/next-page/next-page';
import { PageContent } from '../components/page-content/page-content';
import { useData } from '../hooks/use-data';

const Article: React.FC = () => {
    let { articleId } = useParams();
    console.log('articleId', articleId);
    const { loading, data, error } = useData(`articles/${articleId}`);
    if (loading || error || !data) return null;

    return (
        <div>
            <Banner preHeading={data.type} heading={data.heading} img={data.img} theme={Theme.White} share />
            <PageContent {...data.pageContent} />
            <NextPage {...data.nextPage} />
        </div>
    );
};

export { Article as default }