import React from 'react';
import { Theme } from '../../types/theme';
import { Logo } from '../logo/logo';
import { Menu } from '../menu/menu';
import './nav-bar.scss';
import { useRouteMatch } from 'react-router-dom';
import { ScrollDirection, useScroll } from '../../hooks/use-scroll';

const data = {
    navLinks: [
        { path: '/', label: 'Home' },
        { path: '/about-us', label: 'About Us' },
        { path: '/work', label: 'The Work' },
        { path: '/news-and-opinion', label: 'News & Opinion' },
        { path: '/contact', label: 'Contact' },
    ],
    socialLinks: [
        //{ url: 'https://www.google.com/', label: 'Instagram' },
        //{ url: 'https://www.google.com/', label: 'Facebook' },
        { url: 'https://au.linkedin.com/company/hosthavas', label: 'LinkedIn' },
    ],
    footerLinks: [
        { path: '/privacy', label: 'Privacy Policy' },
        //{ path: '/terms-and-conditions', label: 'Terms and Conditions' },
        //{ path: '/havas-village', label: 'Havas Village' }, // TODO: is this internal or external?
    ]
};

export const NavBar: React.FC = () => {
    const hacMatch = useRouteMatch('/hot+cold');
    let caseStudyMatch = useRouteMatch('/work/:caseStudyId');
    let isCaseStudy = caseStudyMatch && caseStudyMatch.params.caseStudyId !== "archive";
    let isHome = useRouteMatch({ path: '/', exact: true });
    let isANV = useRouteMatch('/australian-national-values');
    const { scrollDir, scrollPosition } = useScroll();
    let theme = (isCaseStudy || isHome || isANV) ? Theme.Black : Theme.White;

    if (hacMatch) return null;

    return (
        <div className={`nav-bar nav-bar-${theme}` + (scrollDir === ScrollDirection.Up ? ' visible' : '') + (scrollPosition > 0 ? ' sticky' : '')}>
            <div><Logo /></div>
            <div><Menu navLinks={data.navLinks} socialLinks={data.socialLinks} footerLinks={data.footerLinks} /></div>
        </div>
    );
};