import React from 'react';

export const useIsLoaded = (delay = 1000) => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoaded(true);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return isLoaded;
};