import React from 'react';
import { useWindowSize } from '../../hooks/use-window-size';
import { Theme } from '../../types/theme';
import { BannerVideo } from '../banner-video/banner-video';
import { ScrollDown } from '../scroll-down/scroll-down';
import './home-banner.scss';

const videos = [
    { src: '/assets/videos/home/video1.mp4', thumbnail: '/assets/images/branding-green.jpg' },
    { src: '/assets/videos/home/video2.mp4', thumbnail: '/assets/images/branding-green.jpg' },
    { src: '/assets/videos/home/video3.mp4', thumbnail: '/assets/images/branding-orange.jpg' },
    { src: '/assets/videos/home/video4.mp4', thumbnail: '/assets/images/branding-orange.jpg' },
];

export const HomeBanner: React.FC = () => {
    const items = [
        {
            heading: "People have never cared less about brands.<br/>We make ours matter.",
            bgVideo: videos[Math.floor(Math.random() * videos.length)],
            video: { src: 'https://d1oijlx36w7lup.cloudfront.net/creds-film.mp4', thumbnail: '/assets/images/branding-green.jpg' }
        },
        {
            heading: "Get to the good stuff with Budget’s new brand platform",
            bgImg: {
                url: "/assets/images/get-to-the-good-stuff/carousel.jpg",
                alt: "People in a car driving under a rainbow."
            },
            path: '/work/get-to-the-good-stuff'
        },
        {
            heading: "XXXX celebrates the thrills (but mainly the spills) of Origin",
            bgImg: {
                url: "/assets/images/the-thrills-and-spills-of-origin/carousel.jpg",
                alt: "A can of XXXX DRY falling while its contents spill out."
            },
            path: '/work/the-thrills-and-spills-of-origin'
        },
        {
            heading: "Havas Labs explores what Aussies want",
            bgImg: {
                url: "/assets/images/what-aussies-want/carousel.jpg",
                alt: "What Aussies Want"
            },
            url: 'https://mumbrella.com.au/were-not-giving-australians-enough-of-what-they-want-684692'
        }
    ];

    const ref = React.useRef<HTMLDivElement>();
    const windowSize = useWindowSize();
    const [activeIndex, setActiveIndex] = React.useState<number>(0);


    const onScrollClick = () => {
        window.scrollTo({ top: ref.current.clientHeight, behavior: 'smooth' });
    };

    const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const prev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const next = () => {
        if (activeIndex < items.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const renderCarouselControls = () => {
        return (
            <div className="carousel-controls">
                <button onClick={prev} disabled={activeIndex <= 0}>Prev</button>
                <span className="line" />
                <button onClick={next} disabled={activeIndex >= items.length - 1}>Next</button>
            </div>
        );
    };

    React.useEffect(() => {
        setVh();
    }, [windowSize]);

    return (
        <div className="home-banner" ref={ref}>
            <div className="carousel">
                <div className="carousel-track" style={{ transform: `translateX(${activeIndex * -100}%)` }}>
                    {items.map((item, index) => {
                        return (
                            <BannerVideo key={`video-${index}`} heading={item.heading} bgImg={item.bgImg} bgVideo={item.bgVideo} video={item.video} path={item.path} url={item.url} renderCarouselControls={renderCarouselControls} />
                        );
                    })}
                </div>
            </div>

            <ScrollDown onClick={onScrollClick} theme={Theme.White} />

        </div >
    );
};