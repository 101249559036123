import React from 'react';
import { PageContent } from '../components/page-content/page-content';
import { PageHeading } from '../components/page-heading/page-heading';

const Careers: React.FC = () => {

    const content = [
        `<ul>
            <li>Digital account manager</li>
            <li>Digital senior account manager</li>
            <li>Account executive</li>
            <li>Designer</li>
            <li>Senior copywriter</li>
            <li>AD (F/L) – 3 month minimum</li>
            <li>Junior planner</li>
            <li>Social media manager</li>
            <li>Content creator (a hybrid design/videography legend)</li>
            <li>Long form content copywriter (6 month contract from January)</li>
            <li>If you are looking for a new role, please get in touch as we’d love to meet you.</li>
        <ul>`,
        `<p>You’d ideally be based in Sydney or Melbourne (although we’re open minded if you are further afield) and for freelance roles, you really can be based anywhere you fancy.</p>`,
        `<p>As an equal opportunity employer, we stay true to our mission of ensuring that our place can be anyone’s place and we welcome diversity in applicants.</p>`,
        `<p>Email: <a href=mailto:vicki.bainbridge@havas.com>vicki.bainbridge@havas.com</a></p>`
    ];

    return (
        <div>
            <PageHeading heading="Open Roles" showScroll />
            <PageContent content={content} />
        </div>
    );
};

export { Careers as default }