import React from 'react';
import './article-link-list.scss';
import { LinkWithArrow } from '../link-with-arrow/link-with-arrow';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { LinkUnderlined } from '../link-underlined/link-underlined';

type ArticleLinkListProps = {
    items: {
        category: string;
        title: string;
        id?: string;
        url?: string;
        publishedDate?: string;
    }[];
};

const getDay = (date: Date) => {
    return date.toLocaleDateString('default', { day: '2-digit' });
};

const getMonthYear = (date: Date) => {
    let month = date.toLocaleDateString('default', { month: 'short' });
    let year = date.toLocaleDateString('default', { year: '2-digit' });
    return `${month} ${year}’`;
};

export const ArticleLinkList: React.FC<ArticleLinkListProps> = ({ items }) => {
    if (!items?.length) return null;
    const articles = items.map(item => ({ ...item, date: new Date(item.publishedDate) })).sort((a, b) => b.date.getTime() - a.date.getTime());
    const limit = 10;
    const [showing, setShowing] = React.useState(Math.min(limit, articles.length));
    const isLoaded = useIsLoaded();

    const showMore = () => {
        if (articles.length > showing) {
            setShowing(showing + Math.min(limit, articles.length - showing));
        }
    };

    return (
        <div className={"article-link-list container spacing" + (isLoaded ? "" : " loading")}>
            <div className="row">
                <div className="col-xs-12 col-md-8 col-md-pre-2">
                    <ul>
                        {articles.slice(0, showing).map((item, index) => {
                            return (
                                <li key={`article-link-${index}`}>
                                    {!!item.publishedDate &&
                                        <div className="article-date">
                                            <h3>{getDay(item.date)}</h3>
                                            <h4>{getMonthYear(item.date)}</h4>
                                        </div>}
                                    <LinkWithArrow
                                        path={item.id ? `/news-and-opinion/${item.id}` : ''}
                                        url={item.url}
                                        title={item.title}
                                        subTitle={item.category} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {articles.length > showing &&
                    <div className="col-xs-12 layout center-xs">
                        <LinkUnderlined onClick={showMore} asButton>See more</LinkUnderlined>
                    </div>
                }
            </div>
        </div>
    );
};