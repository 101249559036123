import React from 'react';

export const useIsPlaying = (ref) => {
    const [isPlaying, setIsPlaying] = React.useState<boolean>();
    const [isPaused, setIsPaused] = React.useState<boolean>();

    const onPlay = () => {
        setIsPlaying(true);
        setIsPaused(false);
    };

    const onPause = () => {
        setIsPlaying(false);
        setIsPaused(true);
    };

    const onEnd = () => {
        setIsPlaying(false);
        setIsPaused(false);
    };

    React.useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('ended', onEnd);
            ref.current.addEventListener('pause', onPause);
            ref.current.addEventListener('play', onPlay);

            return () => {
                ref.current?.removeEventListener('ended', onEnd);
                ref.current?.removeEventListener('pause', onPause);
                ref.current?.removeEventListener('play', onPlay);
            };
        }
    }, [ref]);

    return { isPlaying, isPaused, isActive: isPlaying || isPaused };
};