import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Spacing } from '../../types/spacing';
import './icon-grid.scss';

type IconGridProps = {
    items: {
        icon: Icons;
        title: string;
    }[];
    spacing?: Spacing;
}

export const IconGrid: React.FC<IconGridProps> = ({ items, spacing }) => {
    const refs = items.map(() => React.useRef<HTMLDivElement>());
    const visibility =  refs.map(ref => useIsVisible(ref));
    
    return (
        <div className={"icon-grid container" + (spacing ? ` spacing-${spacing}` : '')}>
            <div className="row layout-align-center">
                <div className="col-xs-12 col-sm-10">
                    <div className="row">
                        {items.map((item, index) => {
                            return (
                                <div 
                                key={`icon-${index}`} 
                                ref={refs[index]} 
                                className={"col-xs icon-grid-item" + (visibility[index] ? '' : ' not-visible')}>
                                    <Icon icon={item.icon} />
                                    <p dangerouslySetInnerHTML={{ __html: item.title }} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};