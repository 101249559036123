import React from 'react';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Category } from '../../types/category';
import { Img } from '../../types/img';
import { PageLinkCard } from '../page-link-card/page-link-card';
import './case-study-grid.scss';

type CaseStudyGridProps = {
    heading?: string;
    items: {
        img: Img;
        clientName: string;
        projectName: string;
        path: string;
        tags: string[];
    }[];
};

export const CaseStudyGrid: React.FC<CaseStudyGridProps> = ({ heading, items }) => {
    //const availableTags = [].concat(...items.map(item => item.tags || [])).filter((value, index, self) => self.indexOf(value) === index);
    //const [selectedFilter, setSelectedFilter] = React.useState<string>();
    //const filteredItems = selectedFilter ? items.filter(item => item.tags?.includes(selectedFilter)) : items;
    //const isLoaded = useIsLoaded(500);

    // const filterByTag = (tag: string) => {
    //     setSelectedFilter(tag);
    // };

    const refs = items.map(() => React.useRef<HTMLDivElement>());
    const visibility = refs.map(ref => useIsVisible(ref));

    return (
        <div className={"case-study-grid container spacing"}>
            {!!heading && <div className="row">
                <div className="col-xs-12 col-md-7 col-lg-6 col-md-pre-2">
                    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>
            </div>}

            <div className="row layout-align-center">
                {/* <div className="col-xs-2 filters">
                    <ul>
                        <li className={!selectedFilter ? 'selected' : ''}><button onClick={() => filterByTag('')}>All</button></li>
                        {availableTags.map((tag, index) => {
                            return (
                                <li key={`filter-${index}`} className={selectedFilter === tag ? 'selected' : ''}><button onClick={() => filterByTag(tag)} >{tag}</button></li>
                            );
                        })}
                    </ul>
                </div> */}
                <div className="col-xs-12 col-md-8">
                    <div className="row">
                        {items.map((item, index) => {
                            return (
                                <div key={`case-study-${index}`} ref={refs[index]} className={"col-xs-12 col-md-6 case-study-grid-item" + (visibility[index] ? '' : ' not-visible')}>
                                    <PageLinkCard {...item} title={item.projectName} subTitle={item.clientName} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};