import React from 'react';
import { PageContent } from '../components/page-content/page-content';
import { PageHeading } from '../components/page-heading/page-heading';
import { SubscribeForm } from '../components/subscribe-form/subscribe-form';
import { BgImg } from '../components/bg-img/bg-img';
import { useData } from '../hooks/use-data';


const Subscribe: React.FC = () => {
    const { loading, data, error } = useData('national-values');
    if (loading || error || !data) return null;

    return (
        <div  style={{color:'white'}}>         
            <BgImg className='absolute' img={...data.img} />
            <PageHeading {...data.heading}  />
            <PageContent  {...data.intro} />
            <SubscribeForm />
        </div>
    );
};

export { Subscribe as default }