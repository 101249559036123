import React from 'react';

export const useMatchMedia = (query: string) => {
    const mediaQuery = window.matchMedia(query);
    const [isMatch, setIsMatch] = React.useState<boolean>(mediaQuery.matches);

    const handleChange = (e) => {
        setIsMatch(e.matches);
    };

    React.useEffect(() => {
        mediaQuery.addListener(handleChange);

        return () => {
            mediaQuery.removeListener(handleChange);
        }
    }, []);

    return isMatch;
};