import React from 'react';
import './staggered-images.scss';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Img } from '../../types/img';

type StaggeredImagesProps = {
    images: Img[];
};

export const StaggeredImages: React.FC<StaggeredImagesProps> = ({ images }) => {
    if (!images?.length) return null;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"staggered-images spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="container">
                <div className="row layout-align-center">
                    <div className="col-xs-12 col-md-8 staggered-images-items">
                        {images.map((img, index) => {
                            return (
                                <img className="staggered-images-item" key={`image-${index}`} src={img.url} alt={img.alt} />
                            );
                        })}
                    </div>
                </div>

            </div>
        </div>
    );
};