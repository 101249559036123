import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Img } from '../../types/img';
import { Spacing } from '../../types/spacing';
import './image-grid.scss';

type ImageGridProps = {
    items: Img[];
    spacing?: Spacing;
    heading?: string;
}

export const ImageGrid: React.FC<ImageGridProps> = ({ items, spacing, heading }) => {
    const refs = items.map(() => React.useRef<HTMLDivElement>());
    const visibility =  refs.map(ref => useIsVisible(ref));
    
    return (
        <div className={"image-grid container" + (spacing ? ` spacing-${spacing}` : '')}>
            <div className="row layout-align-center">
                <div className="col-xs-12 col-sm-8">
                    {!!heading && <div className="row">
                        <div className="col-xs">
                            <h3>{heading}</h3>
                        </div>
                    </div>}
                    <div className="row">
                        {items.map((item, index) => {
                            return (
                                <div 
                                key={`image-${index}`} 
                                ref={refs[index]} 
                                className={"col-xs-6 col-md-3 image-grid-item" + (visibility[index] ? '' : ' not-visible')}>
                                    <div className="image-grid-image-container">
                                    <img className="image-grid-image" src={item.url} alt={item.alt} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};