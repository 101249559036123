import React from 'react';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './contact-cta.scss';
import { useRouteMatch } from 'react-router-dom';

export const ContactCta: React.FC = () => {
    const hacMatch = useRouteMatch('/hot+cold');

    if (hacMatch) return null;

    return (
        <div className="contact-cta spacing">
            <div className="container">
                <div className="row layout-align-center">
                    <div className="col-xs-12 col-md-8 contact-cta-inner">
                        <h2>Don't be shy, say hi.</h2>
                        <LinkUnderlined url="/contact">Get in touch with us</LinkUnderlined>
                    </div>
                </div>
            </div>
        </div>
    );
};