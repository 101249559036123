import React from 'react';
import { CaseStudyGrid } from '../components/case-study-grid/case-study-grid';
import { PageHeading } from '../components/page-heading/page-heading';
import { useData } from '../hooks/use-data';

export const CaseStudies: React.FC = () => {
    const { loading, data, error } = useData('case-studies');
    if (loading || error || !data) return null;
    
    return (
        <div>
            <PageHeading preHeading={data.preHeading} heading={data.heading} />
            <CaseStudyGrid items={data.items} />
        </div>
    );
};

export { CaseStudies as default }