import React from 'react';

export enum Icons {
    App = "APP",
    Arrows = "ARROWS",
    ChevronDown = "CHEVRON_DOWN",
    ChevronRight = "CHEVRON_RIGHT",
    Close = "CLOSE",
    Content = "CONTENT",
    Creative = "CREATIVE",
    Digital = "DIGITAL",
    Identity = "IDENTITY",
    MapMarker = "MAP_MARKER",
    Pause = "PAUSE",
    Play = "PLAY",
    PlayButton = "PLAY_BUTTON",
    PlayDots = "PLAY_DOTS",
    Quote = "QUOTE",
    Share = "SHARE",
    Shop = "SHOP",
    Strategy = "STRATEGY",
    Technology = "TECHNOLOGY",
    Trade = "TRADE",
    Triangle = "TRIANGLE",
    Web = "WEB"
}

export const Icon: React.FC<{ icon: Icons, className?: string }> = ({ icon, className }) => {
    const getSvg = () => {
        switch (icon) {
            case Icons.App:
                return (
                    <svg width="59" height="69" viewBox="0 0 59 69" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.121" y="1.009" width="43.721" height="66.228" rx="3.903" stroke="#fff" strokeWidth="1.772" /><path d="M7.896 10.18H44.77M7.896 56.63h30.648m12.212-6.704V10.179l3.352-5.986 3.352 5.986v44.535m0 0v9.817h-6.704v-9.817h6.704zM17.474 30.771h3.113V19.038H9.094v11.733l7.183-6.944m18.436 6.944h3.113V19.038H26.333v11.733l7.183-6.944m1.197 24.183h3.113V36.278H26.333V48.01l7.183-6.943M17.474 48.01h3.113V36.278H9.094V48.01l7.183-6.943" stroke="#fff" strokeWidth="1.772" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
            case Icons.Arrows:
                return (
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4102 15.8333L16.7303 9.5L10.4102 3.16667" stroke="currentColor" strokeWidth="3.5" />
                        <path opacity="0.7" d="M2.50977 15.8333L8.82994 9.5L2.50977 3.16667" stroke="currentColor" strokeWidth="3.5" />
                    </svg>
                );
            case Icons.ChevronDown:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.4" height="10.1" viewBox="0 0 17.4 10.1"><path d="M8.7 7.3L1.4 0 0 1.4l8.7 8.7 8.7-8.7L16 0 8.7 7.3z" fill="currentColor" /></svg>
                );
            case Icons.ChevronRight:
                return (
                    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.50952 14.667L7.82971 8.33366L1.50952 2.00034" stroke="black" strokeWidth="3.5" />
                    </svg>
                );
            case Icons.Close:
                return (
                    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2" height="2" fill="white" />
                        <rect x="7" y="7" width="2" height="2" fill="white" />
                        <rect x="23" y="7" width="2" height="2" fill="white" />
                        <rect y="28" width="2" height="2" fill="white" />
                        <rect x="7" y="21" width="2" height="2" fill="white" />
                        <rect x="23" y="21" width="2" height="2" fill="white" />
                        <rect x="30" width="2" height="2" fill="white" />
                        <rect x="30" y="28" width="2" height="2" fill="white" />
                        <rect x="15" y="14" width="2" height="2" fill="white" />
                    </svg>
                );
            case Icons.Content:
                return (
                    <svg width="81" height="68" viewBox="0 0 81 68" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.219 58.835V38.084l20.75 9.684-20.75 11.067z" stroke="#fff" strokeWidth="2.02" strokeLinecap="round" strokeLinejoin="round" /><circle cx="19.201" cy="20.883" r="17.201" stroke="#fff" strokeWidth="2.02" /><path d="M35.038 28.803h28.669a3.276 3.276 0 013.276 3.276v31.127a3.276 3.276 0 01-3.276 3.276H19.475a3.276 3.276 0 01-3.277-3.276V37.813M79.543 13.785v21.57a2.73 2.73 0 01-2.73 2.73h-9.284m12.014-24.3L67.529 1.498m12.014 12.287h-8.738a3.276 3.276 0 01-3.276-3.277v-9.01m0 0H52.785a2.73 2.73 0 00-2.73 2.73v24.3" stroke="#fff" strokeWidth="2.02" /><path d="M55.243 10.507h4.914m-4.914 7.372h11.74m-11.74 6.825h18.293M27.12 16.24c-2.094.091-6.28-1.365-6.28-7.918v16.383" stroke="#fff" strokeWidth="2.02" strokeLinecap="round" strokeLinejoin="round" /><circle cx="16.745" cy="27.163" r="4.369" stroke="#fff" strokeWidth="2.02" /></svg>
                );
            case Icons.Creative:
                return (
                    <svg width="63" height="70" viewBox="0 0 63 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M53.452 67.396c-.716-3.401-1.933-11.35-1.074-15.932 1.074-5.729 7.519-12.71 8.414-17.544.895-4.833 5.191-19.155-10.562-28.106-15.754-8.95-33.476-1.61-37.057 3.044-3.58 4.654-5.37 9.309-4.475 13.963.895 4.654 2.327 7.16 0 9.667C6.836 34.493 2.79 38.694 1 40.544l1.432 1.074 6.266 1.432c.41 1.262.903 3.44 1.058 5.908m16.843 19.334c.717-2.865-1.133-6.445-2.148-7.877-3.222 1.074-18.26 2.506-15.753-3.58 1.018-2.474 1.218-5.33 1.058-7.877m6.102 0H9.756" stroke="#fff" strokeWidth="1.79" strokeLinecap="round" strokeLinejoin="round" /><path d="M32.686 30.878a4.656 4.656 0 011.79-8.952 4.654 4.654 0 011.79 8.952" stroke="#fff" strokeWidth="1.79" strokeLinecap="round" /><path d="M31.79 16.176a10.76 10.76 0 012.685-.338c5.932 0 10.742 4.809 10.742 10.74 0 5.933-4.81 10.742-10.742 10.742-5.932 0-10.74-4.809-10.74-10.741 0-3.826 2-7.185 5.012-9.088" stroke="#fff" strokeWidth="1.79" strokeLinecap="round" /><path d="M51.303 26.401c0 9.393-7.614 17.007-17.006 17.007-9.393 0-17.007-7.614-17.007-17.007 0-9.392 7.614-17.006 17.007-17.006 7.83 0 14.424 5.292 16.401 12.494" stroke="#fff" strokeWidth="1.79" strokeLinecap="round" /></svg>
                );
            case Icons.Digital:
                return (
                    <svg width="78" height="65" viewBox="0 0 78 65" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.33" y="1" width="75.062" height="52.148" rx="3.951" stroke="#fff" strokeWidth="1.975" /><path d="M31.157 53.346V63.42m17.185-10.074V63.42M24.639 63.42h30.024" stroke="#fff" strokeWidth="1.975" strokeLinecap="round" strokeLinejoin="round" /><circle cx="22.465" cy="18.185" r="5.926" stroke="#fff" strokeWidth="1.975" /><circle cx="55.256" cy="35.962" r="5.926" stroke="#fff" strokeWidth="1.975" /><path d="M44.456 17.988a6.321 6.321 0 118.43 3.407" stroke="#fff" strokeWidth="1.975" /><path d="M54.663 29.445c0-7.507-4.245-11.062-9.481-11.062a9.481 9.481 0 00-9.482 9.481c0 4.347 2.925 8.01 6.914 9.13.817.23 1.678.352 2.568.352" stroke="#fff" strokeWidth="1.975" strokeLinecap="round" /><path d="M28.194 20.555l8.1 3.16" stroke="#fff" strokeWidth="1.975" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
            case Icons.Identity:
                return (
                    <svg width="42" height="82" viewBox="0 0 42 82" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.245" cy="26.615" r="13.83" stroke="#fff" strokeWidth="1.613" /><path stroke="#fff" strokeWidth="1.613" d="M10.888 43.49h19.035v24.954H10.888z" /><path d="M11.205 51.485h6.238m12.321 0h-5.682m-6.639 0v16.64m0-16.64h6.639m0 0v16.64M10.563 36.768c1.493.96 4.351 3.265 3.84 6.72m13.923-5.44c-.853.48-2.527 1.857-2.399 5.44M13.924 26.21h2.08l2.4 2.88 2.398-2.88 2.48 2.48 2.08-2.48h1.28m-15.437 42.87l9.597 11.836L29.76 69.08M20.802 1.098v7.838m-19.994 0l5.918 5.918m33.752-5.119l-5.839 5.839" stroke="#fff" strokeWidth="1.613" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
            case Icons.MapMarker:
                return (
                    <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="48" height="48" fill="black" />
                        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M15 38.0005H21.08V26.0005H15V38.0005Z" fill="white" />
                        <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M15 20H21.08V8H15V20Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 20V26H27.16V38.0004H33.24V26V20H15Z" fill="white" />
                        <path d="M14 48H34L24 56L14 48Z" fill="black" />
                    </svg>
                );
            case Icons.Pause:
                return (
                    <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2" height="2" fill="white" />
                        <rect width="2" height="2" fill="white" />
                        <rect x="15" width="2" height="2" fill="white" />
                        <rect x="15" y="7" width="2" height="2" fill="white" />
                        <rect y="7" width="2" height="2" fill="white" />
                        <rect x="15" y="21" width="2" height="2" fill="white" />
                        <rect y="21" width="2" height="2" fill="white" />
                        <rect y="28" width="2" height="2" fill="white" />
                        <rect x="15" y="28" width="2" height="2" fill="white" />
                        <rect x="15" y="14" width="2" height="2" fill="white" />
                        <rect y="14" width="2" height="2" fill="white" />
                    </svg>
                );
            case Icons.Play:
                return (
                    <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="2" height="2" fill="white" />
                        <rect width="2" height="2" fill="white" />
                        <rect y="28" width="2" height="2" fill="white" />
                        <rect x="15" y="14" width="2" height="2" fill="white" />
                        <rect y="14" width="2" height="2" fill="white" />
                        <rect y="21" width="2" height="2" fill="white" />
                        <rect x="8" y="21" width="2" height="2" fill="white" />
                        <rect y="7" width="2" height="2" fill="white" />
                        <rect x="8" y="7" width="2" height="2" fill="white" />
                    </svg>
                );
            case Icons.PlayButton:
                return (
                    <svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="41.5" cy="41.5" r="41.5" fill="#fff" /><path fillRule="evenodd" clipRule="evenodd" d="M54.211 41.106a1 1 0 010 1.788l-16.764 8.382A1 1 0 0136 50.382V33.618a1 1 0 011.447-.894l16.764 8.382z" fill="#000" /></svg>
                );
            case Icons.PlayDots:
                return (
                    <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M0 0h2v2H0z" /><path fill="#fff" d="M0 0h2v2H0zM0 28h2v2H0zM15 14h2v2h-2zM0 14h2v2H0zM0 21h2v2H0zM8 21h2v2H8zM0 7h2v2H0zM8 7h2v2H8z" /></svg>
                );
            case Icons.Quote:
                return (
                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.816 31.272V16.488H7.48C7.48 14.12 7.928 12.136 8.824 10.536C9.72 8.872 11.384 7.624 13.816 6.792V0.455996C11.832 0.711996 9.976 1.32 8.248 2.27999C6.584 3.17599 5.144 4.328 3.928 5.736C2.712 7.144 1.752 8.776 1.048 10.632C0.408 12.488 0.12 14.472 0.184 16.584V31.272H13.816ZM34.264 31.272V16.488H27.928C27.928 14.12 28.376 12.136 29.272 10.536C30.168 8.872 31.832 7.624 34.264 6.792V0.455996C32.28 0.711996 30.424 1.32 28.696 2.27999C27.032 3.17599 25.592 4.328 24.376 5.736C23.16 7.144 22.2 8.776 21.496 10.632C20.856 12.488 20.568 14.472 20.632 16.584V31.272H34.264Z" fill="currentColor" />
                    </svg>
                );
            case Icons.Share:
                return (
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="10" width="2" height="2" fill="black" />
                        <rect x="10" y="12" width="2" height="2" fill="black" />
                        <rect y="6" width="2" height="2" fill="black" />
                    </svg>
                );
            case Icons.Shop:
                return (
                    <svg width="78" height="63" viewBox="0 0 78 63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.085 31.216v30.27m19.53-30.27v30.27m41.169-30.27v30.27M1 61.486h75.06M11.34 14.222h59.742m0 0v7.789c-.32 1.475-2.068 4.426-6.51 4.426-4.443 0-6.32-2.95-6.702-4.426v-1.947m13.212-5.842l-6.32-13.099h-51.89l-8.234 13.1v7.788c.383 1.475 2.03 4.426 5.553 4.426 4.404 0 6.702-2.656 6.702-4.426m0 0v-3.186 3.186zm0 0v-1.947m0 1.947c.383 1.475 2.26 4.426 6.702 4.426s6.19-2.95 6.51-4.426v-1.947m1.34 0v1.947c.383 1.475 2.26 4.426 6.702 4.426 4.443 0 6.191-2.95 6.51-4.426v-1.947m.192 0v1.947c.383 1.475 2.26 4.426 6.702 4.426s6.191-2.95 6.51-4.426v-1.947M20.722 40.245v5.133m25.85 6.196l14.17-13.1m-5.553 13.1l6.701-6.196M19.765 8.913L22.062 1.3m10.532 7.612V1.3M43.89 8.913V1.3m12.255 7.612L54.23 1.3" stroke="#fff" strokeWidth="1.916" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
            case Icons.Strategy:
                return (
                    <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.81 64.323c6.031-3.44 19.01-13.84 22.666-27.911 3.657-14.07 0-28.804-2.285-34.412m0 0l-5.715 10.897M30.191 2l11.428 6.5M38.381 51.133L53.81 66.427m0-15.294L37.62 67M2.762 23.602L18.19 38.896m0-15.294L2 39.469" stroke="#fff" strokeWidth="2.574" strokeLinecap="round" strokeLinejoin="round" /><path d="M64.713 27.235c0 5.312-4.289 9.61-9.57 9.61-5.28 0-9.57-4.298-9.57-9.61 0-5.312 4.29-9.61 9.57-9.61 5.281 0 9.57 4.298 9.57 9.61z" stroke="#fff" strokeWidth="2.574" /></svg>
                );
            case Icons.Technology:
                return (
                    <svg width="67" height="72" viewBox="0 0 67 72" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.527 31.245H8.978a2.347 2.347 0 00-2.346 2.346v26.751m38.483-29.097h14.08a2.347 2.347 0 012.346 2.346v26.751M46.525 50.017h-6.806m-16.19 0H34.32m-6.1-.704V14.82h-6.806m12.906 35.198V8.014m0 42.003h5.397m0 0V21.624h7.979M27.282 60.342H1c.313 3.207 2.44 9.668 8.448 9.856 6.007.188 35.668.078 49.747 0 2.112.156 6.43-1.596 6.805-9.856H40.422v2.816h-13.14v-2.816z" stroke="#fff" strokeWidth="1.736" strokeLinecap="round" strokeLinejoin="round" /><circle cx="17.895" cy="15.287" r="3.285" stroke="#fff" strokeWidth="1.736" /><circle cx="34.321" cy="4.494" r="3.285" stroke="#fff" strokeWidth="1.736" /><circle cx="51.217" cy="21.391" r="3.285" stroke="#fff" strokeWidth="1.736" /></svg>
                );
            case Icons.Trade:
                return (
                    <svg width="87" height="63" viewBox="0 0 87 63" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M59.138 52.479H5.614a3.98 3.98 0 01-3.98-3.98V5.92a3.98 3.98 0 013.98-3.98h65.662a3.98 3.98 0 013.98 3.98v21.29" stroke="#fff" strokeWidth="1.99" strokeLinecap="round" /><circle cx="70.481" cy="41.336" r="10.148" stroke="#fff" strokeWidth="1.99" /><path d="M86 56.657l-7.96-7.959m-61.88-3.383v-8.953m8.356 8.953V27.21m8.755 18.106V11.092m8.556 34.223V19.25m8.556 26.065V27.21M33.27 52.479v9.351m12.933-9.352v9.352m-21.688 0H54.76" stroke="#fff" strokeWidth="1.99" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
            case Icons.Triangle:
                return (
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.2111 9.10557C18.9482 9.4741 18.9482 10.5259 18.2111 10.8944L1.44721 19.2764C0.782314 19.6088 0 19.1253 0 18.382L0 1.61803C0 0.874653 0.782312 0.391156 1.44721 0.723607L18.2111 9.10557Z" fill="currentColor" />
                    </svg>
                );
            case Icons.Web:
                return (
                    <svg width="77" height="72" viewBox="0 0 77 72" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.475 59.987V28.12c-.115-1.492.482-4.478 3.79-4.478h13.608m24.977 0h14.47c1.436 0 4.306.896 4.306 4.479V64.12m-33.935-42.03c1.321-.92 4.341-2.206 5.857 0m-10.163 4.995v-8.44c0-2.757 4.708-7.81 7.063-9.992 3.445 2.067 6.373 7.522 7.407 9.991v28.25h-14.47v-1.377m0-18.432c-1.32 2.182-4.513 6.96-6.718 8.613-2.205 1.654-2.756 2.986-2.756 3.445v6.374h9.474m0-18.432v18.432M1.412 64.983H76c-.344 2.01-1.62 6.029-3.962 6.029H5.202c-1.263-.632-3.79-2.722-3.79-6.03zM35.52 47.067c.574 2.87.516 9.44-4.307 12.747.23-1.034.172-3.307-1.895-4.134-2.584-1.034-4.823.344-5.168 2.756M46.2 26.74c.86 2.067 3.203 6.65 5.684 8.44 2.48 1.792 3.215 3.504 3.273 4.135v6.03H46.2m-4.307 1.722c-.574 2.87-.517 9.44 4.306 12.747-.23-1.034-.172-3.307 1.895-4.134 2.584-1.034 4.823.344 5.168 2.756" stroke="#fff" strokeWidth="1.723" strokeLinecap="round" strokeLinejoin="round" /><path d="M23.289 52.237c-1.091-.747-3.79-1.792-5.857 0-2.067 1.791-2.124 4.306-1.895 5.34m46.166 0c.344-2.01-.104-6.098-4.651-6.374-4.548-.276-5.8 2.756-5.857 4.307" stroke="#fff" strokeWidth="1.723" strokeLinecap="round" strokeLinejoin="round" /></svg>
                );
        }
    };

    let el = getSvg();
    return className ? React.cloneElement(el, { className: className }) : el;
}