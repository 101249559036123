import React from 'react';
import { Link } from 'react-router-dom';
import './logo.scss';

export const Logo: React.FC<{onClick?: () => void}> = ({onClick}) => {
    return (
        <Link to="/" className="logo" onClick={onClick}>
            <svg width="37" height="62" viewBox="0 0 37 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M0.00146484 61.0011H12.1615V36.6011H0.00146484V61.0011Z" fill="currentColor" />
                <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M0 24.4005H12.16V0.000488281H0V24.4005Z" fill="currentColor" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0.00146484 24.4004V36.6004H24.3215V61.0012H36.4815V36.6004V24.4004H0.00146484Z" fill="currentColor" />
            </svg>
        </Link>
    );
};