import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './about-us-section.scss';

type AboutUsSectionProps = {
    heading: string;
    link?: {
        url: string;
        label: string;
    };
    content: string[];
}

export const AboutUsSection: React.FC<AboutUsSectionProps> = ({ heading, link, content }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"about-us-section container spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="row">
                <div className="col-xs-12 col-md-6 col-md-pre-2">
                    <h2>{heading}</h2>
                </div>

                <div className="layout-fill" />

                {!!link && <div className="col-xs-12 col-md-3 col-lg-2 col-md-pre-2">
                    <LinkUnderlined url={link.url} external>{link.label}</LinkUnderlined>
                </div>}
                <div className={"col-xs-12 col-md-5 col-lg-6" + (link ? '' : ' col-md-pre-5 col-lg-pre-4')}>
                    {content.map((paragraph, index) => <p key={`content-paragraph-${index}`}>{paragraph}</p>)}
                </div>
            </div>
        </div>
    );
};