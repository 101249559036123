import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import Quote from '../quote';
import './page-content.scss';

type PageContentProps = {
    heading?: string;
    subHeading?: string;
    details?: {
        label: string;
        value: string;
        link?: {
            url: string;
            label: string;
        };
    };
    content?: (string |
    {
        content: string;
        source: string;
    })[];
};

export const PageContent: React.FC<PageContentProps> = ({ heading, subHeading, details, content }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"page-content container spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="row">
                {!!heading &&
                    <React.Fragment>
                        <div className="col-xs-12 col-md-7 col-lg-6 col-md-pre-2">
                            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        </div>
                        <div className="layout-fill" />
                    </React.Fragment>
                }

                {!!subHeading &&
                    <React.Fragment>
                        <div className="col-xs-12 col-md-7 col-lg-6 col-md-pre-2">
                            <h3 dangerouslySetInnerHTML={{ __html: subHeading }} />
                        </div>
                        <div className="layout-fill" />
                    </React.Fragment>
                }

                {!!details && <div className="col-xs-12 col-md-2 col-md-pre-2 page-content-details">
                    <h4 className="details-label">{details.label}</h4>
                    <h4 className="details-value">{details.value}</h4>
                    {!!details.link && <LinkUnderlined url={details.link.url} external>{details.link.label}</LinkUnderlined>}
                </div>}

                {!!content && <div className={"col-xs-12 col-md-6" + (details ? '' : ' col-md-pre-4')}>
                    {content.map((item, index) => {
                        return (
                            <div key={`page-content-${index}`} className="page-content-content">
                                {typeof item === 'object' ? <Quote {...item} /> : <div dangerouslySetInnerHTML={{ __html: item }} />}
                            </div>
                        );
                    })}
                </div>}
            </div>
        </div>
    );
};