import React from 'react';
import { Img } from '../../types/img';
import { BgImg } from '../bg-img/bg-img';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './work-list.scss';
import { Link } from 'react-router-dom';
import { useIsVisible } from '../../hooks/use-is-visible';

type WorkListProps = {
    items: {
        projectName: string | string[];
        client: string;
        img: Img;
        id: string;
    }[];
};

export const WorkList: React.FC<WorkListProps> = ({ items }) => {
    const refs = items.map(() => React.useRef<HTMLDivElement>());
    const visibility = refs.map(ref => useIsVisible(ref));
    const inCenter = refs.map(ref => useIsVisible(ref, { rootMargin: "-25%" }, false)); // load when within a 25% margin of the viewport
    const [isHovered, setIsHovered] = React.useState<boolean>();

    return (
        <div className={"work-list" + (isHovered ? ' hovered' : '')}>
            <div className="container spacing">
                <div className="row layout-align-center">
                    {items.map((item, index) => {
                        return (
                            <div className="col-xs-12 col-md-8" key={`work-link-${item.id}`}>
                                <Link to={`/work/${item.id}`}
                                    className={"work-link" + (visibility[index] ? '' : ' not-visible') + (inCenter[index] ? '' : ' fade')}
                                    ref={refs[index]}
                                    onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                    <BgImg className="work-link-img" img={item.img} />
                                    <div className="work-link-heading">
                                        <h2>{Array.isArray(item.projectName) ? item.projectName.map((line, lineIndex) => <span key={`heading-line-${lineIndex}`}>{line}</span>) : item.projectName}</h2>
                                        <p>{item.client}</p>
                                    </div>
                                    <LinkUnderlined>View the Work</LinkUnderlined>
                                </Link>
                            </div>
                        );
                    })}
                    <div className="col-xs-12 layout center-xs">
                        <LinkUnderlined url="/work/archive">View past projects</LinkUnderlined>
                    </div>
                </div>
            </div>
        </div>
    );
};