import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { Img } from '../../types/img';
import { BgImg } from '../bg-img/bg-img';
import { FullScreenVideo } from '../full-screen-video/full-screen-video';
import './banner-video.scss';
import { LinkHandler } from '../../controllers/link-handler';

type BannerVideoProps = {
    heading: string;
    bgImg?: Img;
    bgVideo?: {
        src: string;
        thumbnail: string
    };
    video?: {
        src: string;
        thumbnail: string
    };
    path?: string;
    url?: string;
    renderCarouselControls: () => void;
}

export const BannerVideo: React.FC<BannerVideoProps> = ({ heading, bgImg, bgVideo, video, path, url, renderCarouselControls }) => {
    const element = React.useRef<HTMLDivElement>();
    const isLoaded = useIsLoaded();
    const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>();

    return (
        <div className={"banner-video" + (isLoaded ? "" : " loading")} ref={element}>
            {!!bgImg && <BgImg img={bgImg} className="banner-video-img" />}


            {!!bgVideo && <video className="banner-video-video" autoPlay loop muted playsInline poster={bgVideo.thumbnail}>
                <source src={bgVideo.src} type="video/mp4"></source>
            </video>}

            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8 col-md-pre-2">
                        {renderCarouselControls()}
                        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                        {!!video && <button className="banner-video-btn" onClick={() => setIsVideoPlaying(true)}>
                            <Icon icon={Icons.PlayDots} />
                            <span className="banner-video-btn-label">See how we do it</span>
                        </button>}
                        <LinkHandler className="banner-video-btn" path={path} url={url}>
                            <span className="banner-video-btn-label">Find out more</span>
                        </LinkHandler>
                    </div>
                </div>
            </div>

            {!!video && <FullScreenVideo src={video.src} thumbnail={video.thumbnail} play={isVideoPlaying} onClose={() => setIsVideoPlaying(false)} />}
        </div>
    );
};