import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './share.scss';

export const Share: React.FC = () => {
    const currentUrl = encodeURIComponent(window.location.href);

    const shareLinks = [
        { label: 'Twitter', link: `http://twitter.com/share?url=${currentUrl}` },
        { label: 'Facebook', link: `https://www.facebook.com/sharer.php?u=${currentUrl}` },
        { label: 'LinkedIn', link: `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}` },
    ];

    const [isOpen, setIsOpen] = React.useState<boolean>();
    const ref = React.useRef<HTMLDivElement>();

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    React.useEffect(() => {
        if (isOpen) {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }

    }, [ref, isOpen]);

    return (
        <div className={"share" + (isOpen ? ' open' : '')} ref={ref}>
            <LinkUnderlined onClick={onClick} asButton>
                <span>Share</span>
                <Icon icon={Icons.Share} />
            </LinkUnderlined>
            <ul>
                {shareLinks.map((item, index) => {
                    return (
                        <li key={`share-link-${index}`}>
                            <LinkUnderlined url={item.link} external target="_blank" onClick={closeMenu}>{item.label}</LinkUnderlined>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};