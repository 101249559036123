import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ContactCta } from './components/contact-cta/contact-cta';
import { Footer } from './components/footer/footer';
import { NavBar } from './components/nav-bar/nav-bar';
import { ScrollToTop } from './components/scroll-to-top/scroll-to-top';
import PageNotFound from './controllers/404';
import AboutUs from './controllers/about-us';
import Article from './controllers/article';
import Articles from './controllers/articles';
import CaseStudies from './controllers/case-studies';
import CaseStudy from './controllers/case-study';
import Contact from './controllers/contact';
import Home from './controllers/home'; // Don't lazy load Home page
import Privacy from './controllers/privacy';
import Careers from './controllers/careers';
import Work from './controllers/work';
import HotAndCold from './controllers/hot-and-cold';
import Report from './controllers/report';
import Subscribe from './controllers/subscribe';
import 'regenerator-runtime/runtime'

// TODO: add a better loading screen

// const AboutUs = React.lazy(() => import('./controllers/about-us'));
// const Article = React.lazy(() => import('./controllers/article'));
// const Articles = React.lazy(() => import('./controllers/articles'));
// const CaseStudy = React.lazy(() => import('./controllers/case-study'));
// const CaseStudies = React.lazy(() => import('./controllers/case-studies'));
// const Contact = React.lazy(() => import('./controllers/contact'));
// const PageNotFound = React.lazy(() => import('./controllers/404'));
// const Privacy = React.lazy(() => import('./controllers/privacy'));
// const Work = React.lazy(() => import('./controllers/work'));

const App = () => {
    return (
        <div>
            <Router>
                <ScrollToTop />
                <NavBar />
                {/* <Suspense fallback={<div className="loading"></div>}> */}
                <div className="main">
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/news-and-opinion/:articleId">
                            <Article />
                        </Route>
                        <Route exact path="/news-and-opinion">
                            <Articles />
                        </Route>
                        <Route exact path="/work">
                            <Work />
                        </Route>
                        <Route exact path="/work/archive">
                            <CaseStudies />
                        </Route>
                        <Route exact path="/work/:caseStudyId">
                            <CaseStudy />
                        </Route>
                        <Route exact path="/contact">
                            <Contact />
                        </Route>
                        <Route exact path="/privacy">
                            <Privacy />
                        </Route>
                        <Route exact path="/careers">
                            <Careers />
                        </Route>
                        <Route exact path="/about-us">
                            <AboutUs />
                        </Route>
                        <Route exact path="/hot+cold">
                            <HotAndCold />
                        </Route>
                        <Route exact path="/hot+cold/:caseStudyId">
                            <CaseStudy />
                        </Route>
                        <Route exact path="/australian-national-values">
                            <Subscribe />
                        </Route>
                        <Route exact path="/prosumer-report-2023">
                            <Report />
                        </Route>
                        <Route path="*">
                            <PageNotFound />
                        </Route>
                    </Switch>
                </div>
                {/* </Suspense> */}
                <ContactCta />
                <Footer />
            </Router>
        </div>
    );
}

window.onload = () => {
    const root = document.getElementById("root");
    ReactDOM.render(<App />, root);
}