import React from 'react';
import { HomeBanner } from '../components/home-banner/home-banner';
import { PageLinkGrid } from '../components/page-link-grid/page-link-grid';
import { useData } from '../hooks/use-data';

const Home: React.FC = () => {
    const { loading, data, error } = useData('home');
    if (loading || error || !data) return null;

    return (
        <div>
            <HomeBanner />
            <div className="container spacing">
                <div className="row layout-align-center">
                    <div className="col-xs-12 col-md-8">
                        <PageLinkGrid items={data.featured} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Home as default }