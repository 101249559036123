import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Img } from '../../types/img';
import { BgImg } from '../bg-img/bg-img';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './next-page.scss';

type NextPageProps = {
    preHeading: string;
    heading: string[];
    img: Img;
    link: {
        path: string;
        text: string;
    };
};

export const NextPage: React.FC<NextPageProps> = ({ preHeading, heading, img, link }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"next-page" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-10 col-md-pre-2 next-page-inner">
                        <div className="next-page-heading">
                            <h4 className="next-page-pre-heading">{preHeading}</h4>
                            <h2>{heading.map((line, index) => <span key={`heading-line-${index}`}>{line}</span>)}</h2>
                        </div>
                        <LinkUnderlined url={link.path}>{link.text}</LinkUnderlined>
                    </div>
                </div>
            </div>
            <div className="next-page-image-container">
                <BgImg img={img} />
            </div>
        </div>
    );
};