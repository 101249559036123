import React from "react";
import { LinkUnderlined } from "../link-underlined/link-underlined";
import "./footer.scss";
import { useRouteMatch } from "react-router-dom";

const data = {
  pageLinks: [
    { path: "/about-us", label: "About Us" },
    { path: "/work", label: "The Work" },
    { path: "/news-and-opinion", label: "News & Opinion" },
    { path: "/contact", label: "Contact" },
  ],
  address: {
    value: `121 Harrington Street, Sydney NSW 2000
        Australia`,
    directions:
      "https://www.google.com/maps/dir//121+Harrington+St,+The+Rocks+NSW+2000/@-33.8623722,151.2043885,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x6b12ae43d9802539:0x7fe8c90e2a242aa5!2m2!1d151.2065772!2d-33.8623722!3e0",
  },
  contact: [
    { label: "Ant Moore, Managing Director" },
    { url: "tel: +61 2 9963 7880", label: "+61 2 9963 7880" },
    { url: "mailto:ant.moore@hosthavas.com", label: "Ant.Moore@hosthavas.com" },
  ],
  socialLinks: [
    //{ url: 'https://www.google.com/', label: 'Instagram' },
    //{ url: 'https://www.google.com/', label: 'Facebook' },
    { url: "https://au.linkedin.com/company/hosthavas", label: "LinkedIn" },
  ],
  footerLinks: [
    { path: "/privacy", label: "Privacy Policy" },
    //{ path: '/terms-and-conditions', label: 'Terms and Conditions' },
    //{ path: '/havas-village', label: 'Havas Village' }, // TODO: is this internal or external?
  ],
};

export const Footer: React.FC = () => {
  const hacMatch = useRouteMatch("/hot+cold");

  if (hacMatch) return null;

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-2 col-md-pre-2">
              <p className="footer-heading">Address</p>
              <p dangerouslySetInnerHTML={{ __html: data.address.value }} />
              <LinkUnderlined
                url={data.address.directions}
                external
                target="_blank"
              >
                Get directions
              </LinkUnderlined>
            </div>
            <div className="col-xs-12 col-md-5">
              <p className="footer-heading">New Business Enquiries</p>
              <ul>
                {data.contact.map((link, index) => {
                  return link.url ? (
                    <li key={`contact-link-${index}`}>
                      <LinkUnderlined url={link.url} external>
                        {link.label}
                      </LinkUnderlined>
                    </li>
                  ) : (
                    <p key={`contact-link-${index}`}>{link.label}</p>
                  );
                })}
              </ul>
            </div>
            <div className="col-xs-12 col-md">
              <p className="footer-heading">Follow us</p>
              <ul>
                {data.socialLinks.map((link, index) => (
                  <li key={`social-link-${index}`}>
                    <LinkUnderlined url={link.url} external>
                      {link.label}
                    </LinkUnderlined>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-pre-2">
              <small className="copyright">
                &copy;Host/Havas {currentYear}
              </small>
              <ul>
                {data.footerLinks.map((link, index) => (
                  <li key={`footer-link-${index}`}>
                    <LinkUnderlined url={link.path}>
                      {link.label}
                    </LinkUnderlined>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
