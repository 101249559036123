import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { Img } from '../../types/img';
import { Theme } from '../../types/theme';
import { BgImg } from '../bg-img/bg-img';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import { ScrollDown } from '../scroll-down/scroll-down';
import { Share } from '../share/share';
import './banner.scss';

type BannerProps = {
    preHeading: string;
    heading: string;
    img: Img;
    theme: Theme;
    share?: boolean;
}

export const Banner: React.FC<BannerProps> = ({ preHeading, heading, img, theme, share }) => {
    const isLoaded = useIsLoaded(500);
    const element = React.useRef<HTMLDivElement>();

    const onScrollClick = () => {
        window.scrollTo({ top: element.current.clientHeight, behavior: 'smooth' });
    };

    return (
        <div className={`banner banner-${theme}` + (isLoaded ? "" : " loading")} ref={element}>
            <div className="grid">
                <div className="heading">
                    <h4 className="pre-heading">{preHeading}</h4>
                    <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>
                {!!share && <Share />}
                <ScrollDown onClick={onScrollClick} theme={theme === Theme.Black ? Theme.White : Theme.Black} />
            </div>
            <div className="image-container">
                <BgImg img={img} />
            </div>
        </div>
    );
};