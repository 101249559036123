import React from 'react';
import { PageContent } from '../components/page-content/page-content';
import { PageHeading } from '../components/page-heading/page-heading';
import { ProsumerReport } from '../components/prosumer-report/prosumer-report';
import { BgImg } from '../components/bg-img/bg-img';
import { useData } from '../hooks/use-data';


const Report: React.FC = () => {
    const { loading, data, error } = useData('prosumer-report-2023');
    if (loading || error || !data) return null;

    return (
        <div  style={{color:'white'}}>         
            <BgImg className='absolute' img={...data.img} />
            <PageHeading {...data.heading}  />
            <PageContent  {...data.intro} />
            <ProsumerReport />
        </div>
    );
};

export { Report as default }