import React from 'react';
import './map.scss';
import GoogleMapReact from 'google-map-react';
import MapStyles from '../../assets/data/google-map-styles.json';
import { Icon, Icons } from '../../controllers/icon';
import { useIsVisible } from '../../hooks/use-is-visible';

type MapProps = {
    latitude: number;
    longitude: number;
};

const MapMarker = ({ lat, lng }) => <span className="map-marker"><Icon icon={Icons.MapMarker} /></span>;

export const Map: React.FC<MapProps> = ({ latitude, longitude }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);
    
    return (
        <div className="container spacing" ref={ref}>
            <div className="row center-md">
                <div className="col-xs-12 col-md-8">
                    <div className={"map-container" + (isVisible ? '' : ' not-visible')}>
                        <div className="map">
                            <GoogleMapReact
                                options={{ styles: MapStyles }}
                                bootstrapURLKeys={{ key: "AIzaSyC87QkhS8cYjKkXD54l7AGwrBUywFI6H54" }}
                                center={{
                                    lat: latitude,
                                    lng: longitude
                                }}
                                zoom={16}
                            >
                                <MapMarker
                                    lat={latitude}
                                    lng={longitude}></MapMarker>
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};