import React from 'react';
import { useIsLoaded } from '../../hooks/use-is-loaded';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './contact-details.scss';

type ContactDetailsProps = {
    address: string;
    directions: string;
    tel: string;
    email: string;
    socialLinks: {
        url: string;
        label: string;
    }[];
};

export const ContactDetails: React.FC<ContactDetailsProps> = ({ address, directions, tel, email, socialLinks }) => {
    const isLoaded = useIsLoaded(100);

    return (
        <div className={"contact-details container spacing" + (isLoaded ? "" : " loading")}>
            <div className="row">
                <div className="col-xs-12 col-md-4 col-lg-3 col-md-pre-2 contact-details-address">
                    <p className="address" dangerouslySetInnerHTML={{ __html: address }} />
                    <LinkUnderlined url={directions} external target="_blank">Get directions</LinkUnderlined>
                </div>
                <div className="col-xs-12 col-md-4 contact-details-links">
                    <p className="contact-field"><span className="contact-label">Tel:</span> <LinkUnderlined url={`tel:${tel}`} external>{tel}</LinkUnderlined></p>
                    <p className="contact-field"><span className="contact-label">Email:</span> <LinkUnderlined url={`mailto:${email}`} external>{email}</LinkUnderlined></p>
                    <p className="contact-field"><span className="contact-label">Follow us:</span>
                        <span className="contact-value">
                            {socialLinks.map((link, index) => <LinkUnderlined key={`social-link-${index}`} url={link.url} external>{link.label}</LinkUnderlined>)}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};