import React from 'react';
import { Icons } from '../../controllers/icon';
import { AboutUsSection } from '../about-us-section/about-us-section';
import { IconGrid } from '../icon-grid/icon-grid';
import './capabilities.scss';

export const Capabilities: React.FC<{heading: string, content: string[], items: {icon: Icons, title: string}[]}> = ({heading, content, items}) => {
    return (
        <div className="capabilities spacing">
            <AboutUsSection heading={heading}  content={content} />
            <IconGrid items={items} />
        </div>
    );
};