import React from 'react';
import utilsUI from '../../core/utilsUI';
import { useIsVisible } from '../../hooks/use-is-visible';
import { Img } from '../../types/img';
import "./bg-img.scss";

type BgImgProps = {
    img?: Img;
    mobileImg?: Img;
    className?: string;
    overlay?: boolean;
};

export const BgImg: React.FC<BgImgProps> = ({ img, mobileImg, className, overlay }) => {

    let style: React.CSSProperties = {};
    let image = !utilsUI.isScreen('md') && mobileImg ? mobileImg : img;
    if (image?.url) {
        style.backgroundImage = `url(${image.url})`;
        style.backgroundColor = 'transparent';
    }
    if (image?.focalPoint?.x) style.backgroundPositionX = `${image.focalPoint.x * 100}%`;
    if (image?.focalPoint?.y) style.backgroundPositionY = `${image.focalPoint.y * 100}%`;

    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref, { rootMargin: "200px" }); // load when 200px away from entering the viewport

    return (
        <div
            ref={ref}
            className={"bg-img" + (className ? ` ${className}` : "") + (overlay ? " bg-img-overlay" : "")}
            style={isVisible ? style : null}>
            <span className="sr-only">{image?.alt || image?.title}</span>
        </div>
    );
}