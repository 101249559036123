import React from 'react';
import { useIsVisible } from '../../hooks/use-is-visible';
import { LinkUnderlined } from '../link-underlined/link-underlined';
import './case-study-intro.scss';

type CaseStudyIntroProps = {
    project?: string;
    heading: string;
    link?: {
        url: string;
        label: string;
    };
    overview: string;
    results?: string[];
}

export const CaseStudyIntro: React.FC<CaseStudyIntroProps> = ({ project, heading, link, overview, results }) => {
    const ref = React.useRef<HTMLDivElement>();
    const isVisible = useIsVisible(ref);

    return (
        <div className={"case-study-intro container spacing" + (isVisible ? '' : ' not-visible')} ref={ref}>
            <div className="row">
                {!!project && <div className="col-xs-12 col-md-2">
                    <h4>{project}</h4>
                </div>
                }
                <div className={"col-xs-12 col-md-7 col-lg-6" + (project ? '' : ' col-md-pre-2')}>
                    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>

                <div className="layout-fill" />

                {!!link && <div className="col-xs-12 col-md-2 col-md-pre-2">
                    <LinkUnderlined url={link.url} external target="_blank"><span dangerouslySetInnerHTML={{ __html: link.label }}></span></LinkUnderlined>
                </div>}
                <div className={"col-xs-12 col-md-6 spacing-bottom" + (link ? '' : ' col-md-pre-2')}>
                    <p dangerouslySetInnerHTML={{ __html: overview }} />
                    {!!results?.length &&
                    <React.Fragment>
                        <br/>
                        <p>Results</p>
                        <ul>
                            {results.map((item, index) => {
                                return (
                                    <li key={`result-item-${index}`}>{item}</li>
                                );
                            })}
                        </ul>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    );
};