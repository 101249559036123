import React from 'react';
import { LinkHandler } from '../../controllers/link-handler';
import { Category } from '../../types/category';
import { Img } from '../../types/img';
import { BgImg } from '../bg-img/bg-img';
import './page-link-card.scss';

type PageLinkCardProps = {
    img: Img;
    category?: Category;
    title: string;
    subTitle?: string;
    orientation?: 'landscape' | 'portrait';
    path?: string;
    url?: string;
};

export const PageLinkCard: React.FC<PageLinkCardProps> = ({ img, category, title, subTitle, orientation, path, url }) => {
    return (
        <LinkHandler path={path} url={url} className="page-link-card">
            {!!category && <h4 className="page-link-card-category">{category}</h4>}
            <div className={"page-link-img-container" + (orientation ? ` ${orientation}` : '')}>
                <BgImg className="page-link-img" img={img} />
            </div>
            {!!subTitle && <h4 className="page-link-card-sub-title">{subTitle}</h4>}
            <h3 className="page-link-card-title">{title}</h3>
        </LinkHandler>
    );
};