import React from 'react';
import { WorkList } from '../components/work-list/work-list';
import { useData } from '../hooks/use-data';

const Work: React.FC = () => {
    const { loading, data, error } = useData('work');
    if (loading || error || !data) return null;
    
    return (
        <div>
            <WorkList items={data.items} />
        </div>
    );
};

export { Work as default }