import React from 'react';
import { Icon, Icons } from '../../controllers/icon';
import { Theme } from '../../types/theme';
import './scroll-down.scss';

type ScrollDownProps = {
    theme: Theme;
    onClick: () => void;
}

export const ScrollDown: React.FC<ScrollDownProps> = ({ theme, onClick }) => {
    return (
        <button className={`scroll-down scroll-down-${theme}`} onClick={onClick}>
            <Icon icon={Icons.ChevronDown} />
        </button>
    );
};